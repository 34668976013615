// selectors linked to syncronization status
import * as R from 'ramda';

import { ActionEnum } from '../constants';

export const getLastSync = R.pathOr(0, ['authStatus', 'lastSync']);

export const isSynchronizing = R.path(['status', 'isSynchronizing']);

const isAllActionNone = R.all(R.propEq('action', ActionEnum.NONE))
const notesIsAllActionNone = R.compose(isAllActionNone, R.prop('notes'));
const sessionsIsAllActionNone = R.compose(isAllActionNone, R.prop('sessions'));

export const isSynchronized = R.converge(R.and, [notesIsAllActionNone, sessionsIsAllActionNone])

// export const isSynchronized = state =>
//     state['notes'].every((n) => n['action']===ActionEnum.NONE) &&
//     state['sessions'].every((n) => n['action']===ActionEnum.NONE)
