// Selectors linked to the authentication status
import * as R from 'ramda';

export const getOnline = R.path(["status", 'online'])

export const getToken = R.path(['authStatus','token'])

export const isAuthenticated = R.path(['authStatus', 'isAuthenticated'])

export const getCurrentUser = R.path(['authStatus', 'currentUser'])

export const getClientId = R.path(['authStatus', 'clientId'])

export const getCurrentGroupName = R.path(['authStatus', 'currentGroup'])

export const getGroups = R.prop('groups')

const findByName = R.compose(R.find, R.propEq('name'));
const findByGroupName = R.compose(findByName, getCurrentGroupName);

// export const getCurrentGroup = state => {
//   const groupName = getCurrentGroupName(state);
//   const groups = getGroups(state);
//   if(groups) {
//     return groups.find( g => g['name'] === groupName );
//   } else {
//     return null;
//   }
// }
// https://stackoverflow.com/a/48924396

export const getCurrentGroup = R.unnest(R.converge(R.compose, [findByGroupName, R.always(getGroups)]))

export const getCreateGroup = R.prop('createGroup')

export const getAutoSubmit = R.prop('autoSubmit')

export const getLoginErrorMessages = R.path(['login', 'errorMessages'])



