import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sessionsActions from '../actions/sessionsActions';
import * as authActions from '../actions/authActions';
import _ from 'lodash';
import { withNamespaces } from 'react-i18next';


class SessionForm extends Component {

  state = {
    createGroup: false,
    protocolOpen: false,
    titleEditMode: false,
    descriptionEditMode: false,
    enterKeyValue: false,
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const changes = { [name]: value }
    this.onChangeThrottle(changes);
  }

  onChangeThrottle = _.debounce((changes) => {
    this.props.sessionsActions.updateSession(this.props.currentSession._id, changes);
  }, 750)

  onGroupChange = (e) => {
    const groupName = e.target.value;

    const group = this.props.groups.find((g) => g.name === groupName);

    this.props.sessionsActions.updateSession(this.props.currentSession, { group: groupName, protocol: group?group.protocol:'' });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (nextState.createGroup && nextProps.createGroup.success) {
      this.setState({ createGroup: false })
    }
  }

  toggleProtocol = (e) => {
    e.preventDefault();
    this.setState({
      protocolOpen: !this.state.protocolOpen
    });
  }

  toggleOnTitleEditMode = () => {
    this.setState({
      titleEditMode: true,
    });
  }

  toggleOnDescriptionEditMode = () => {
    this.setState({
      descriptionEditMode: true,
    });
  }

  toggleOffTitleEditMode = () => {
    this.setState({
      titleEditMode: false,
    });
  }

  toggleOffDescriptionEditMode = () => {
    this.setState({
      descriptionEditMode: false,
    });
  }

  handleClickOutside = (e) => {
    if (this.title && !this.title.contains(e.target)) {
      this.toggleOffTitleEditMode()
    }

    if (this.desc && !this.desc.contains(e.target)) {
      this.toggleOffDescriptionEditMode()
    }
  }

  saveEdit = (e) => {

    // e.preventDefault();

    // if (e.key === 'Enter') {
    //   this.setState({enterKeyValue: true})
    // }

    // if (e.key !== 'Enter') {
    //   this.setState({enterKeyValue: false})
    // }

    if (e.key === 'Enter' && this.state.enterKeyValue === true) {

      e.preventDefault();
      this.setState({
        enterKeyValue: false,
        titleEditMode: false,
        descriptionEditMode: false,
      })
    }
  }

  titleEditMode = () => {

    const t = this.props.t;

    if (this.state.titleEditMode === false) {
      return (
      <div
        onClick={this.toggleOnTitleEditMode}
        className='session-page-title border-0 bg-irinotes-headers text-muted ml-3'
      >
        { this.props.currentSession.title || <span className='session-placeholder'>{ t('session_form.empty_title_placeholder') }</span> }
      </div>
    );
    }

    if (this.state.titleEditMode === true) {
    return (
    <div className="form-group pr-5">
      <textarea className='session-page-title form-control border-primary bg-secondary text-muted ml-3  session-placeholder'
        onKeyDown={ this.saveEdit }
        onChange={ this.onChange }
        type="textarea"
        name="title"
        defaultValue={ this.props.currentSession.title }
        placeholder={ t('session_form.empty_title_placeholder') }
        ref={title => this.title = title}
      ></textarea>
    </div>
    );
    }
  }

  descriptionEditMode = () => {

  const t = this.props.t;

  if (this.state.descriptionEditMode === false) {
    return (
    <div
      onClick={this.toggleOnDescriptionEditMode}
      className="session-page-description border-0 bg-irinotes-headers text-muted ml-3"
    >
      { this.props.currentSession.description || <span className='session-placeholder'>{ t('session_form.empty_description_placeholder') }</span> }
    </div>
  );
  }

  if (this.state.descriptionEditMode === true) {
  return (
    <div className="form-group mt-2 pb-2">
      <textarea className="session-page-description form-control border-primary bg-secondary text-muted ml-3 session-placeholder"
        onKeyDown={ this.saveEdit }
        onChange={ this.onChange }
        type="textarea"
        name="description"
        defaultValue={ this.props.currentSession.description }
        placeholder={ t('session_form.empty_description_placeholder') }
        ref={desc => this.desc = desc}
      ></textarea>
    </div>
  );
  }
}

  render() {

    if (!this.props.currentSession) {
      return (
        <form></form>
      )
    }

    return (
        <div className="session-page-panel panel-default sticky-top">
          <div className="session-page-card card-body bg-secondary pr-5">
            <form onSubmit={ e => { e.preventDefault() } }>
              {this.titleEditMode()}
              {this.descriptionEditMode()}
            </form>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state, props) {

  let group;
  if (props.session && props.session.group) {
    group = state.groups.find(group => props.session.group === group.name)
  }

  return {
    currentSession: props.session,
    createGroup: state.createGroup,
    groups: state.groups,
    group
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sessionsActions: bindActionCreators(sessionsActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SessionForm));
