import React from 'react';

export default ({ annotationCategories }) => (
  <div className="protocol-summary">
  {annotationCategories.map((category) => (
    <div className="protocol-summary-category" key={category.key} >
      <span className="protocol-summary-category-name badge text-light" style={{ backgroundColor: category.color, fontSize: "1em" }} >{category.name}</span>
      <p className="text-primary">{category.description}</p>
    </div>
  ))}
  </div>
);
