import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { withNamespaces, Trans } from 'react-i18next';

// import logo from './logo.svg';
import Modal from 'react-modal';
import * as authActions from '../actions/authActions';
import * as sessionsActions from '../actions/sessionsActions';
import { forceSync } from '../actions/networkActions';
import { groupSetCurrent } from '../actions/groupActions';
import { isAuthenticated, getCurrentUser, getOnline, getCurrentGroup, getGroups } from '../selectors/authSelectors';
import { isSynchronizing, isSynchronized } from '../selectors/syncSelectors';
import CreateSession from './CreateSession';
import NavbarLogin from './NavbarLogin';
import NavbarGroup from './NavbarGroup';

const Online = ({ online }) => {
  return (
    <li className="nav-item">
      <span className="nav-link">
        <span  className="material-icons" style={{ color: online ? '#2ECC71' : '#F95B5B' }}>signal_wifi_4_bar</span>
      </span>
    </li>
  )
}

const SyncButton = ({ isAuthenticated, onSyncClick, isSynchronizing, isSynchronized, id }) => {
  const classnames = "material-icons"
    + ((!isSynchronized)?" sync-button-not-synchronized":"")
    + ((isSynchronizing)?" sync-button-synchronizing":"");
  let title = "Synchronize";
  let clickCb = onSyncClick;
  if(isSynchronizing) {
    title = "Synchronizing...";
    clickCb = () => {};
  } else if (!isSynchronized) {
    title += ": not synchronized";
  }

  if (isAuthenticated) {
    return (
      <li className="nav-item">
        <span className="nav-link sync-button" title={title} onClick={clickCb} id={id || null}>
          <span className={classnames}>&#xE627;</span>
        </span>
      </li>
    );
  }
  return (
      <li>
        <span>
          <span></span>
        </span>
    </li>
  );
}

const OffLineMessage = ({isAuthenticated}) => {
  if (!isAuthenticated) {
    return (
        <span className="sticky-top text-warning text-right float-right mr-4 offline-message"><Trans i18nKey="navbar.offline_message"></Trans></span>
    );
  }
    return (
      <span></span>
  );
}


class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };
  }

  componentWillMount() {
    Modal.setAppElement('body');
}

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  handleModalCloseRequest = () => {
    // opportunity to validate something and keep the modal open even if it
    // requested to be closed
    this.setState({modalIsOpen: false});
  }

  onClickHome = (e) => {
    e.preventDefault();
    this.props.history.push('/');
  }

  isSynchronized = () => {
    return this.props.isSynchronized;
  }

  onClickLogout = (e) => {
    e.preventDefault();
    const isSynchronized = this.isSynchronized();
    if (isSynchronized) {
      this.logout();
    } else {
      this.openModal()
    }
  }

  confirmLogout = () => {
    const isSynchronized = this.isSynchronized();
    if (!isSynchronized) {
      this.props.authActions.resetAll();
    }
    this.logout();
    this.closeModal();
  }

  logout = () => {
    this.props.authActions.logout();
    this.props.history.push('/');
  }

  onClickSessions = (e) => {
    e.preventDefault();
    this.props.history.push('/sessions');
  }

  onSyncClick = (e) => {
    e.preventDefault();
    this.props.networkActions.forceSync();
  }

  onGroupSelect = (groupName) => {
    if(groupName === "__create_group__") {
      this.props.history.push('/create-group');
    } else {
      this.props.groupActions.groupSetCurrent(groupName);
    }
  }

  render() {
    const t = this.props.t;
    return (
      <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-primary sticky-top">
        <div className="container-fluid">
        <div className="navbar-header">
        <a className="navbar-brand" /*onClick={this.onClickHome}*/ href="/">IRI Notes</a>
        <button className="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item text-secondary">
                <a className="nav-link text-capitalize" onClick={this.onClickSessions} href="/sessions">{t('common.session', {count: 2})}</a>
                {/* <CreateSession
                    history={this.props.history}
                    group={this.props.currentGroup}
                    createSession={this.props.sessionsActions.createSession}
                  /> */}
              </li>
            </ul>
            <ul className="navbar-nav navbar-center">
                <li className="nav-item text-secondary">
                {/* <a className="nav-link " onClick={this.onClickSessions} href="/sessions">Sessions</a> */}
                  <CreateSession
                    history={this.props.history}
                    group={this.props.currentGroup}
                    createSession={this.props.sessionsActions.createSession}
                  />
                </li>
            </ul>
            <ul className="nav navbar-nav ml-auto">
              <NavbarGroup currentGroup={this.props.currentGroup} groups={this.props.groups} onSelect={this.onGroupSelect}/>
              <SyncButton id='sync-button' isAuthenticated={this.props.isAuthenticated} onSyncClick={this.onSyncClick} isSynchronizing={this.props.isSynchronizing} isSynchronized={this.props.isSynchronized} />
              <Online {...this.props} />
              <NavbarLogin {...this.props} onLogout={this.onClickLogout} />
            </ul>
          </div>
          <Modal
          className="Modal__Bootstrap modal-dialog modal-dialog-centered"
          // closeTimeoutMS={150}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          >
            <div id="logout-modal" className="modal-content">
              <span id="logout-close-modal-button" className="material-icons p-0 text-right" onClick={ this.handleModalCloseRequest }>close</span>
              <div className="modal-body text-center">
              <span className="material-icons modal-warning text-info pb-5">warning</span>
                <p className="modal-text"><Trans i18nKey="navbar.logout_modal">
                  Certaines sessions n'ont pas encore été sauvegardées.
                  <br />
                  Si vous continuez, elles seront perdues.
                  </Trans></p>
              <button type="button" className="btn btn-danger text-secondary font-weight-bold py-1 px-2 mb-3 text-capitalize" id="logout-modal-button" onClick={ this.confirmLogout }>{t('common.confirm')}</button>
              </div>
            </div>
          </Modal>
        </div>
      </nav>
      <OffLineMessage isAuthenticated={this.props.isAuthenticated} />
      </div>
    );
  }
}

AppNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  return {
    isAuthenticated: isAuthenticated(state),
    currentUser: getCurrentUser(state),
    online: getOnline(state),
    isSynchronizing: isSynchronizing(state),
    isSynchronized: isSynchronized(state),
    currentGroup: getCurrentGroup(state),
    groups: getGroups(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    networkActions: bindActionCreators({ forceSync }, dispatch),
    groupActions: bindActionCreators({ groupSetCurrent }, dispatch),
    sessionsActions: bindActionCreators(sessionsActions, dispatch),
  }
}

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AppNavbar)));
