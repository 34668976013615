import createRootReducer from '../reducers';
import rootAuthSaga from '../sagas/authSaga';
import rootGroupSaga from '../sagas/groupSaga';
import rootSyncSaga from '../sagas/syncSaga';
import networkSaga from '../sagas/networkSaga';
import { compose, createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import APIClient from '../api/APIClient';
import config from '../config';
import asyncRequest from '../constants/asyncRequest';

const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
    }) : compose;


const defaultState = {
  sessions: [],
  notes: [],
  groups: [],
  status: {
    isSynchronizing: false,
    online: false
  },
  authStatus: {
    token: '',
    isAuthenticated: false,
    clientId: null,
    lastSync: 0,
    currentUser: null,
    currentGroup: null
  },
  autoSubmit: false,
  login: asyncRequest,
  register: asyncRequest,
  createGroup: asyncRequest
};

const persistOptions = {
  key: 'root',
  storage: localForage,
  whitelist: ['sessions', 'notes', 'autoSubmit', 'authStatus', 'groups']
}

const apiClient = new APIClient(config.apiRootUrl);

export default (history, initialState = defaultState) => {

  const persistedReducer = createRootReducer(history, (rootReducer) => persistReducer(persistOptions, rootReducer));
  const router = routerMiddleware(history);
  const saga = createSagaMiddleware();

  const store = createStore(persistedReducer, initialState, composeEnhancers(
    applyMiddleware(router, saga),
  ));

  apiClient.setStore(store);

  const context = {
    client: apiClient,
    history
  }

  const persistor = persistStore(store, null, () => {
    saga.run(rootAuthSaga, context);
    saga.run(rootGroupSaga, context);
    saga.run(rootSyncSaga, context);
    saga.run(networkSaga, context);
  });


  return { store, persistor };
};
