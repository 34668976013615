import uuidV1 from 'uuid/v1';

import * as types from '../constants/actionTypes';
// import WebAnnotationSerializer from '../api/WebAnnotationSerializer';
import { ActionEnum } from '../constants';

export const addNote = (session, data) => {
  const noteId = uuidV1();
  const note = {
    _id: noteId,
    session: session._id,
    raw: data.raw,
    plain: data.plain,
    html: data.html,
    startedAt: data.startedAt,
    finishedAt: data.finishedAt,
    categories: data.categories,
    marginComment: data.marginComment,
    action: ActionEnum.CREATED
  };

  return {
    type: types.ADD_NOTE,
    note,
  };
}

export const deleteNote = (note) => {
  return {
    type: types.DELETE_NOTE,
    note,
  };
}

export const updateNote = (note, data) => {

  return {
    type: types.UPDATE_NOTE,
    note,
    data,
  };
}

export const doDeleteNote = (noteId) => {
  return { type: types.DO_DELETE_NOTE, noteId };
}

export const doDeleteAllNote = () => {
  return { type: types.DO_DELETE_ALL_NOTE };
}

export const loadNote = (note) => {
  return { type: types.LOAD_NOTE, note };
}

export const resetActionNote = (note) => {
  return { type: types.RESET_ACTION_NOTE, note };
}

