import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class NavbarGroup extends Component {

  state = {
    showDropdown: false
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    const currentNode = ReactDOM.findDOMNode(this);
    if(currentNode && !currentNode.contains(e.target)) {
      this.hideDropDown();
    }
  }

  toggleShowDropdown = () => {
    this.setState({showDropdown: !this.state.showDropdown});
  }

  hideDropDown = () => {
    this.setState({showDropdown: false});
  }

  render() {

    const {currentGroup, groups, onSelect} = this.props;

    const onClickGroup = (e) => {
      e.preventDefault();
      onSelect(e.target.dataset.groupname);
      this.hideDropDown();
    }

    if (currentGroup) {
      const currentGroupName = currentGroup.name;
      return (
        <li className={`nav-item dropdown ${this.state.showDropdown?'show':''}`}>
          <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded={this.state.showDropdown} onClick={this.toggleShowDropdown} onBlur={this.hideDropDown}>
            { currentGroupName }
            &nbsp;<span className="caret"></span>
          </span>
          <div id="group-menu-scroll-down" className={`dropdown-menu dropdown-menu-right shadow py-1 pt-3 mb-5 mt-2 bg-primary border border-primary ${this.state.showDropdown?'show':''}`} aria-labelledby="navbarDropdown">
          { groups && groups.map((group, key) => {
            const groupName = group.name;
            const className = (groupName === currentGroupName)?'active':null;
              return <button key={key} onClick={onClickGroup} className={`dropdown-item btn btn-primary bg-primary text-secondary font-weight-bold ${className}`} data-groupname={groupName}>{ groupName }</button>
            })
          }
          <button type="button "onClick={onClickGroup} key="-1" className="dropdown-item btn btn-primary bg-primary text-center text-secondary create-group" data-groupname="__create_group__">Créer un groupe</button>
          </div>
        </li>
      );
    } else {
      return null;
    }
  }
}
