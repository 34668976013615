import React, { Component } from 'react';

// import logo from './logo.svg';
import Navbar from './components/Navbar';

class App extends Component {
  render() {
    return (
      <div>
        <Navbar history={this.props.history} />
        <div className="container-fluid"></div>
          <div className="row">
            {/* <div className="col-lg-6 offset-md-3 text-center mt-5 pt-5">
              <h5 className="font-weight-bold text-center text-lg mt-5">Bienvenue sur</h5>
              <h4 className="font-weight-bold text-center text-lg">IRI Notes</h4>
          </div> */}
        </div>
      </div>
    );
  }
}

export default App;
