import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { PersistGate } from 'redux-persist/integration/react'

import App from './App';
import SessionList from './components/SessionList';
import Session from './components/Session';
import Login from './components/Login';
import CreateGroup from './components/CreateGroup';
import ReadOnlySession from './components/ReadOnlySession';
import Register from './components/Register';
import Reset from './components/Reset';
import Settings from './components/Settings';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import config from './config';
import AuthenticatedRoute from './misc/AuthenticatedRoute';

import './i18n';

const history = createHistory({
    basename: config.basename
});
const { store, persistor } = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} >
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/sessions/:id" component={Session} />
          <Route exact path="/sessions" component={SessionList} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/create-group" component={CreateGroup} />
          <Route exact path="/read-only/:id" component={ReadOnlySession} />
          <AuthenticatedRoute exact path="/settings" component={Settings} store={store} />
          <Route exact path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
