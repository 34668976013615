import * as types from '../constants/actionTypes';

export const loginSubmit = (username, password) => {
  return {
    type: types.AUTH_LOGIN_SUBMIT,
    username,
    password
  };
}

export const logout = () => {
  return {
    type: types.AUTH_LOGOUT
  };
}

export const registerSubmit = (username, email, password1, password2) => {
  return {
    type: types.AUTH_REGISTER_SUBMIT,
    username,
    email,
    password1,
    password2
  };
}

export const createGroup = (name) => {
  const group = {
    name
  };

  return {
    type: types.GROUP_CREATE_ASYNC,
    group,
  };
}

export const resetAll = () => {
  return { type: types.SYNC_RESET_ALL }
}

export const resetSubmit = (email) => {
  return {
    type: types.AUTH_RESET_SUBMIT,
    email,
  };
}

