// see https://gist.github.com/fdidron/ebcf52dc1ed62ff7d80725854d631a9e

import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router';
import { isAuthenticated } from '../selectors/authSelectors';

const AuthenticatedRoute = ({component, ...props}) => {

  const { store } = props;
  const state = store.getState();

  if (isAuthenticated(state)) {
    return <Route { ...props } component={ component } />;
  }

  return <Redirect to="/login" />;
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ])
};

export default AuthenticatedRoute;
