export const NOOP = 'NOOP';

export const ADD_NOTE = 'ADD_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const DO_DELETE_NOTE = 'DO_DELETE_NOTE';
export const DO_DELETE_ALL_NOTE = 'DO_DELETE_ALL_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const RESET_ACTION_NOTE = 'RESET_ACTION_NOTE';
export const LOAD_NOTE = 'LOAD_NOTE';

export const CREATE_SESSION = 'CREATE_SESSION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DO_DELETE_SESSION = 'DO_DELETE_SESSION';
export const DO_DELETE_ALL_SESSION = 'DO_DELETE_ALL_SESSION';
export const LOAD_SESSIONS = 'LOAD_SESSIONS';
export const LOAD_SESSION = 'LOAD_SESSION';
export const RESET_ACTION_SESSION = 'RESET_ACTION_SESSION';

export const AUTH_LOGIN_SUBMIT = 'AUTH_LOGIN_SUBMIT';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

export const AUTH_REGISTER_SUBMIT = 'AUTH_REGISTER_SUBMIT';
export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';

export const AUTH_RESET_SUBMIT = 'AUTH_RESET_SUBMIT';
export const AUTH_RESET_REQUEST = 'AUTH_RESET_REQUEST';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';

// Used both by login & register
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';

export const STATUS_ONLINE = 'STATUS_ONLINE';
export const STATUS_OFFLINE = 'STATUS_OFFLINE';

export const AUTH_STORE_TOKEN_ASYNC = 'AUTH_STORE_TOKEN_ASYNC';
export const AUTH_STORE_TOKEN = 'AUTH_STORE_TOKEN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_DEAUTHENTICATE = 'AUTH_DEAUTHENTICATE';

export const GROUP_CREATE_ASYNC = 'GROUP_CREATE_ASYNC';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_ERROR = 'GROUP_CREATE_ERROR';
export const GROUP_SET_GROUP = 'GROUP_SET_GROUP';

export const GROUP_LOAD_ASYNC = 'GROUP_LOAD_ASYNC';
export const GROUP_LOAD_SUCCESS = 'GROUP_LOAD_SUCCESS';
export const GROUP_LOAD_ERROR = 'GROUP_LOAD_ERROR';

export const USER_UPDATE_SETTINGS_ASYNC = 'USER_UPDATE_SETTINGS_ASYNC';
export const USER_UPDATE_SETTINGS = 'USER_UPDATE_SETTINGS'
export const USER_TOGGLE_AUTO_SUBMIT = 'USER_TOGGLE_AUTO_SUBMIT';

export const DATA_FETCH_SUCCESS = 'DATA_FETCH_SUCCESS';

export const SYNC_DO_SYNC = 'SYNC_DO_SYNC';
export const SYNC_START_SYNC = 'SYNC_START_SYNC';
export const SYNC_END_SYNC = 'SYNC_END_SYNC';
export const SYNC_SET_LAST_SYNC = 'SYNC_SET_LAST_SYNC';
export const SYNC_RESET_ALL = 'SYNC_RESET_ALL';
