import React from 'react'
import Html from 'slate-html-serializer'

const BLOCK_TAGS = {
  p: 'paragraph',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  li: 'list-item',
}

// Add a dictionary of mark tags.
const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underlined',
  category: 'span'
}

const rules = [
  // Block rules
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName]
      if (!type) return
      return {
        object: 'block',
        type: type,
        nodes: next(el.childNodes)
      }
    },
    serialize(obj, children) {
      if (obj.object !== 'block') return
      switch (obj.type) {
        case 'numbered-list':
          return <ol>{children}</ol>;
        case 'bulleted-list':
          return <ul>{children}</ul>;
        case 'list-item':
          return <li>{children}</li>;
        case 'paragraph':
        case 'line':
          return <p>{children}</p>
        default: return;
      }
    }
  },
  // Mark rules
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName]
      if (!type) return
      return {
        object: 'mark',
        type: type,
        nodes: next(el.childNodes)
      }
    },
    serialize(obj, children) {
      if (obj.object !== 'mark') return
      switch (obj.type) {
        case 'bold':
          return <strong>{children}</strong>
        case 'italic':
          return <em>{children}</em>
        case 'underlined':
          return <ins>{children}</ins>
        case 'category':
          return <span style={{ backgroundColor: obj.data.get('color') }}>{children}</span>
        default: return;
      }
    }
  }
]

const serializer = new Html({ rules })

export default serializer
