import moment from 'moment'

export const defaultDateTimeformat = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const formatTimestamp = (ts) => {
  return moment(ts).local().format('H:mm:ss');
}

export const now = () => {
  return moment.utc().format(defaultDateTimeformat);
}
