import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAutoSubmit } from '../../selectors/authSelectors';
import * as userActions from '../../actions/userActions';

function mapStateToProps(state, props) {

  const autoSubmit = getAutoSubmit(state);

  return {
    autoSubmit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
}

// see https://github.com/facebook/react/issues/3005 for explanation about the timeout.
const ToolbarCheckbox = connect(mapStateToProps, mapDispatchToProps)(({ autoSubmit, userActions }) => (
  <div className="checkbox float-right">
    <label className="mr-2">
      <input type="checkbox" checked={autoSubmit} onChange={(e) => { setTimeout(userActions.setAutoSubmit, 0, e.target.checked) }} value="enterBox" /><small className="text-muted ml-1"><Trans i18nKey="slate_editor.press_enter_msg">Appuyer sur <kbd className="bg-irinotes-form text-muted ml-1">Entrée</kbd> pour ajouter une note</Trans></small>
    </label>
  </div>
));

export default ({ hasNote, isButtonDisabled, submitNote }) => (
  <div>
    <button type="button" id="btn-editor" className="btn btn-primary btn-sm text-secondary font-weight-bold float-right text-capitalize" disabled={isButtonDisabled} onClick={submitNote}>
      { hasNote ? <Trans i18nKey="common.save">Save</Trans> : <Trans i18nKey="common.add">Add</Trans> }
    </button>
    { !hasNote && <ToolbarCheckbox /> }
  </div>
);

