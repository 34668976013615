import { put, take, all } from 'redux-saga/effects'
import * as types from '../constants/actionTypes';

// ---

function* watchLoginSubmit() {
  while (true) {
    const { username, password } = yield take(types.AUTH_LOGIN_SUBMIT);
    yield put({ type: types.AUTH_LOGIN_REQUEST, username, password });
  }
}

function* watchLoginRequest(context) {
  while (true) {
    try {

        const { username, password } = yield take(types.AUTH_LOGIN_REQUEST);
        const client = context.client;
        const response = yield client.post('/api/auth/login/', { username, password });

        const actions = [{
          type: types.AUTH_STORE_TOKEN_ASYNC,
          token: response.token,
        },
        {
          type: types.AUTH_LOGIN_SUCCESS,
          user: response.user,
          token: response.token,
        }];

        yield all(actions.map(action => put(action)));
        context.history.push('/sessions');

    } catch(e) {
        yield put({ type: types.AUTH_LOGIN_ERROR, error: e });
    }
  }
}

export function* watchRegisterSubmit() {
  while (true) {
    const { username, email, password1, password2 } = yield take(types.AUTH_REGISTER_SUBMIT);
    yield put({ type: types.AUTH_REGISTER_REQUEST, username, email, password1, password2 });
  }
}

function* watchRegisterRequest(context) {
  while (true) {
    try {

        const { username, email, password1, password2 } = yield take(types.AUTH_REGISTER_REQUEST);

        const client = context.client;
        const response = yield client.post('/api/auth/registration/', {
          username,
          email,
          password1,
          password2
        });

        const actions = [{
          type: types.AUTH_STORE_TOKEN_ASYNC,
          token: response.token,
        }, {
          type: types.AUTH_LOGIN_SUCCESS,
          user: response.user,
          token: response.token,
        }];

        yield all(actions.map(action => put(action)));
        context.history.push('/sessions');

    } catch(e) {
      yield put({ type: types.AUTH_REGISTER_ERROR, error: e });
    }
  }
}

function* watchStoreToken() {
  while (true) {
    const { token } = yield take(types.AUTH_STORE_TOKEN_ASYNC);
    yield put({ type: types.AUTH_STORE_TOKEN, token });
  }
}

function* watchUpdateSettings(context) {
  while (true) {
    const { username, firstname, lastname } = yield take(types.USER_UPDATE_SETTINGS_ASYNC);
    const client = context.client;
    try {
      yield client.put('/api/auth/user/', {
        username,
        first_name: firstname,
        last_name: lastname
      });
      yield put({ type: types.USER_UPDATE_SETTINGS, firstname, lastname });
    } catch (e) {
      //TODO: handle error
    }
  }
}

export function* watchResetSubmit() {
  while (true) {
    const { email } = yield take(types.AUTH_RESET_SUBMIT);
    yield put({ type: types.AUTH_RESET_REQUEST, email });
  }
}


function* watchResetRequest(context) {
  while (true) {
    try {

        const { email } = yield take(types.AUTH_RESET_REQUEST);

        const client = context.client;
        yield client.post('/api/auth/password/reset/', { email });

        context.history.push('/sessions');

    } catch(e) {
      yield put({ type: types.AUTH_RESET_ERROR, error: e });
    }
  }
}



// ---

export default function* rootSaga(context) {
  yield all([
    watchLoginSubmit(),
    watchLoginRequest(context),
    watchRegisterSubmit(),
    watchRegisterRequest(context),
    watchStoreToken(),
    watchUpdateSettings(context),
    watchResetSubmit(),
    watchResetRequest(context),
  ])
}
