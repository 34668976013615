import * as R from 'ramda';
import * as types from '../constants/actionTypes';
import UserRecord from '../store/userRecord';
import GroupRecord from '../store/groupRecord';
import asyncRequest from '../constants/asyncRequest';
import uuidV4 from 'uuid/v4';


export const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case types.AUTH_DEAUTHENTICATE:
    case types.AUTH_LOGOUT:
      return false;
    case types.AUTH_LOGIN_SUCCESS:
      return true;
    default:
      return state;
  }
}

export const currentUser = (state = null, action) => {
  switch (action.type) {
    case types.AUTH_DEAUTHENTICATE:
    case types.AUTH_LOGOUT:
      return null;
    case types.AUTH_LOGIN_SUCCESS:
      return new UserRecord(action.user);
    case types.USER_UPDATE_SETTINGS:
      return R.merge(state,{
        first_name: action.firstname,
        last_name: action.lastname
      });
    default:
      return state;
  }
}

export const currentGroup = (state = null, action) => {
  switch (action.type) {
    case types.AUTH_DEAUTHENTICATE:
    case types.AUTH_LOGOUT:
      return null;
    case types.AUTH_LOGIN_SUCCESS:
      if( state === null) {
        return action.user.default_group;
      }
      return state;
    case types.GROUP_CREATE_SUCCESS: {
      return action.group.name;
    }
    case types.GROUP_SET_GROUP:
      return action.group;
    default:
      return state;
  }
}


export const token = (state = null, action) => {
  switch (action.type) {
    case types.AUTH_DEAUTHENTICATE:
    case types.AUTH_LOGOUT:
      return null;
    case types.AUTH_STORE_TOKEN:
      return action.token;
    default:
      return state;
  }
}

export const clientId = (state = null, action) => {
  switch (action.type) {
    case types.AUTH_DEAUTHENTICATE:
    case types.AUTH_LOGOUT:
      return null;
    case types.AUTH_LOGIN_SUCCESS:
      return uuidV4();
    default:
      return state;
  }

}

export const login = (state = asyncRequest, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN_REQUEST:
      return {
        loading: true,
        success: false,
        error: false,
      }
    case types.AUTH_LOGIN_SUCCESS:
    case types.AUTH_LOGIN_ERROR:
      return {
        loading: false,
        success: action.type === types.AUTH_LOGIN_SUCCESS,
        error: action.type === types.AUTH_LOGIN_ERROR,
        errorMessages: action.type === types.AUTH_LOGIN_ERROR ? action.error : {}
      }
    default:
      return state
  }
}

export const register = (state = asyncRequest, action) => {
  switch (action.type) {
    case types.AUTH_REGISTER_REQUEST:
      return {
        loading: true,
        success: false,
        error: false,
      }
    case types.AUTH_LOGIN_SUCCESS:
    case types.AUTH_REGISTER_ERROR:
      return {
        loading: false,
        success: (action.type === types.AUTH_LOGIN_SUCCESS || action.type === types.AUTH_LOGIN_SUCCESS),
        error: action.type === types.AUTH_REGISTER_ERROR,
        errorMessages: action.type === types.AUTH_REGISTER_ERROR ? action.error : {}
      }
    default:
      return state
  }
}

const groupTransformIsPersonal = R.compose(
  GroupRecord,
  R.converge(
    R.merge,
    [
      R.omit('is_personal'),
      R.compose(
        R.objOf('isPersonal'),
        R.propOr(false, 'is_personal')
      )
    ]
  )
);

export const groups = (state = [], action) => {
  switch (action.type) {
    case types.GROUP_LOAD_SUCCESS:
      return R.map(groupTransformIsPersonal, action.groups)
    case types.GROUP_CREATE_SUCCESS:
      return R.append(groupTransformIsPersonal(action.group), state);
    case types.AUTH_LOGOUT: {
      return []; // empty note list on logout
    }
    default:
      return state
  }
}

export const createGroup = (state = asyncRequest, action) => {
  switch (action.type) {
    case types.GROUP_CREATE_ASYNC:
      return {
        loading: true,
        success: false,
        error: false,
      }
    case types.GROUP_CREATE_SUCCESS:
    case types.GROUP_CREATE_ERROR:
      return {
        loading: false,
        success: action.type === types.GROUP_CREATE_SUCCESS,
        error: action.type === types.GROUP_CREATE_ERROR,
        errorMessages: action.type === types.GROUP_CREATE_ERROR ? action.error : {}
      }
    default:
      return state
  }
}
