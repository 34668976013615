import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../actions/authActions';
import { Trans } from 'react-i18next';
import * as R from 'ramda';

class Reset extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: ''
    }


  }

  reset = () => {
    const { email } = this.state;

    this.props.authActions.resetSubmit(email);
  }

  handleChange = (event) => {
    const newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  submit = (e) => {
    e.preventDefault();

    this.reset();
  }

  onClickLogin = (e) => {
    e.preventDefault();
    this.props.history.push('/login');
  }

  renderErrorMessage(errorMessages, fieldname) {
    if (errorMessages && fieldname in errorMessages) {
      return errorMessages[fieldname].map((message, key) =>
        <p key={ key } className="form-text alert alert-danger mt-4" role="alert" >{ message }</p>
      );
    }
  }

  renderNonFieldErrors(errorMessages) {

    if (errorMessages && errorMessages.error) {
      return (
        <div className="alert alert-danger mt-4" role="alert">
          <Trans i18nKey="login.login_error">Unable to log in.</Trans>
        </div>
      )
    }

    const errors = R.reduce(
      (acc, p) => R.concat(acc, R.ifElse(Array.isArray, R.identity, v => [v,])(R.pathOr([], ['data', p], errorMessages))),
      [],
      ['non_field_errors', 'detail']
    );
    if (errors && errors.length > 0 ) {
      return (
        <div className="alert alert-danger mt-4" role="alert">
        { errors.map((message, key) =>
          <p key={ key }><Trans i18nKey="reset.reset_error">{ message }</Trans></p>
        ) }
        </div>
      )
    }

  }


  render() {

    const errorMessages = this.props.register.error ? this.props.register.errorMessages : false ;

    return (
      <div>
        {/* <Navbar history={this.props.history} /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 offset-md-3">
              <div className="panel-login panel panel-default">
                <div className="card-header bg-secondary border-0 mt-5 pt-5">
                  <h4 className="text-center card-title font-weight-bold text-lg" onClick={this.onClickHome}>IRI Notes</h4>
                  <form className="pt-3 ml-5 pl-5" onSubmit={this.submit}>
                    <div className="form-group mb-2 ml-3 w-75">
                      <label className="col-form-label text-primary font-weight-bold mt-2" htmlFor="email"><Trans i18nKey="common.email">Email</Trans></label>
                      <input className="form-control bg-irinotes-form border-0 text-muted" type="email" onChange={this.handleChange} value={this.state.email} name="email" />
                      { errorMessages && this.renderErrorMessage(errorMessages.data, 'email') }
                    </div>
                    { this.renderNonFieldErrors(errorMessages) }
                    <div className="text-center mr-5 pr-5">
                    <button type="submit" onClick={this.submit} className="btn btn-primary btn-lg text-secondary font-weight-bold mt-3"><Trans i18nKey="reset.reset">Reset passord</Trans></button>
                    </div>
                  </form>
                </div>
              </div>
              <p className="text-center">
                <a className="text-muted" href="/login" onClick={ this.onClickLogin }><Trans i18nKey="register.already_registered">Déjà inscrit ? Se connecter</Trans>.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    register: state.register
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
