import * as types from '../constants/actionTypes';

export const lastSync = (state = 0, action) => {
  switch (action.type) {
    case types.SYNC_SET_LAST_SYNC:
      return action.value;
    case types.AUTH_LOGOUT:
      return 0;
    default:
      return state;
  }
}

export const isSynchronizing = (state = false, action) => {
  switch (action.type) {
    case types.SYNC_START_SYNC:
      return true;
    case types.SYNC_END_SYNC:
      return false;
    default:
      return state;
  }
}
