export const ActionEnum = {
  NONE: 0,
  CREATED: 1,
  UPDATED: 2,
  DELETED: 3
}

export const defaultAnnotationsCategories = [
  { key: 'important', name: 'Important',    color: '#F1C40F' },
  { key: 'keyword',   name: 'Mot-clé',      color: '#2ECC71' },
  { key: 'comment',   name: 'Commentaire',  color: '#3498DB', hasComment: true }
];


export const extractAnnotationCategories = (protocol) => {
  const metacategories = (protocol)?protocol['metacategories']:null;
  if(!metacategories) {
    return defaultAnnotationsCategories;
  }
  return metacategories.map((m) => {
    return {
      key: m.id,
      name: m.name || m.title,
      description: m.description,
      color: m.color,
      hasComment: m.has_comment
    }
  })
}
