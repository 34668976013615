import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal  from 'react-modal';
import Note from './Note';
import { Trans } from 'react-i18next';

class NotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingNote: null,
      noteToDelete: null,
      modalIsOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.notes || []).length > (prevProps.notes || []).length) {
      this.node.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    }
  }

  componentWillMount() {
    Modal.setAppElement('body');
}

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  enterEditMode = (note) => {
    this.setState({ editingNote: note });
  }

  closeNote = () => {
    this.setState({ editingNote: null });
  }

  confirmDelete = (note) => {
    this.setState({
      modalIsOpen: true,
      noteToDelete: note
    })
  }

  deleteNote = () => {
    const { noteToDelete } = this.state;
    this.props.deleteNote(noteToDelete);
    this.closeModal();
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      noteToDelete: null
    })
  }

  handleModalCloseRequest = () => {
    // opportunity to validate something and keep the modal open even if it
    // requested to be closed
    this.setState({
      modalIsOpen: false,
      noteToDelete: null
    });
  }


  updateNote = (note, data) => {
    this.props.updateNote(note, data);
  }

  render() {
    if (this.props.notes.size === 0) {
      return (
        <div></div>
      );
    }

    return (
      <div>
        <div>
          {this.props.notes.map((note) =>
            <Note
              note={ note }
              key={ note._id}
              onClick={ this.enterEditMode.bind(this, note) }
              onClose={ this.closeNote }
              onDelete={ this.confirmDelete.bind(this, note) }
              onSave={ this.updateNote }
              isEditing={ this.state.editingNote && note === this.state.editingNote }
              annotationCategories={this.props.annotationCategories} />
          )}
            <div className="dummy-div" ref={node => this.node = node}/>
          </div>

        <Modal
        className="Modal__Bootstrap modal-dialog modal-dialog-centered w-25"
        // closeTimeoutMS={150}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.handleModalCloseRequest}
        >
          <div id="delete-note-modal" className="modal-content">
            <span id="delete-note-close-modal-button" className="material-icons text-right" onClick={ this.handleModalCloseRequest }>close</span>
            <div className="modal-body text-center">
            <span className="modal-text"><Trans i18nKey='notes_list.delete_note_msg'>Supprimer cette note ?</Trans></span>
              <button type="button" className="btn btn-danger text-secondary font-weight-bold py-1 px-2 ml-3" id="delete-note-modal-button" onClick={ this.deleteNote }><Trans i18nKey='common.delete'>Supprimer</Trans></button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

NotesList.propTypes = {
  notes: PropTypes.array.isRequired
};

export default NotesList;
