import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationFR from "./locales/fr/translation.json";
import translationEN from "./locales/en/translation.json";

// the translations
const resources = {
  fr: {
    translation: translationFR
  },
  en: {
    translation: translationEN
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    fallbackLng: "fr",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
