import * as types from '../constants/actionTypes';


export const groupCreateSuccess = (group) =>
  ({ type: types.GROUP_CREATE_SUCCESS, group });

export const groupCreateError = (error) =>
  ({ type: types.GROUP_CREATE_ERROR, error });

export const groupLoadSucess = (groups) =>
  ({ type: types.GROUP_LOAD_SUCCESS, groups });

export const groupLoadError = (error) =>
  ({ type: types.GROUP_LOAD_ERROR, error });

export const groupLoadAsync = () =>
  ({ type: types.GROUP_LOAD_ASYNC });

export const groupSetCurrent = (groupName) =>
  ({ type: types.GROUP_SET_GROUP, group: groupName });

export const createGroup = (name, description) => {
  return {
    type: types.GROUP_CREATE_ASYNC,
    group: {
      name,
      description
    }
  };
}
