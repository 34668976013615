import * as R from 'ramda';

class WebAnnotationSerializer {

  static serialize = (note) => {

    const categories = R.prop('categories', note);

    const baseAnnotation = {
      '@context': "http://www.w3.org/ns/anno.jsonld",
      "type":     "Annotation",
    }

    const source = "/session/" + R.prop('session', note) + "/notes/" + R.prop('_id', note);

    return categories.map((category, index) => {

      let annotation = Object.assign({}, baseAnnotation, {
        id: index
      });

      if (category.hasOwnProperty('hasComment') && category.hasComment) {
        const body = {
          "type": "TextualBody",
          "value": category.comment,
          "format": "text/plain"
        };

        annotation = Object.assign({}, annotation, { body })
      }

      const selectors = [
        {
          "type": "TextQuoteSelector",
          "exact": category.text,
        }, {
          "type": "TextPositionSelector",
          "start": category.selection.start,
          "end": category.selection.end,
        }
      ]

      return Object.assign({}, annotation, {
        "target": {
          "source": source,
          "selector": selectors
        }
      })
    });

  }

}

export default WebAnnotationSerializer
