import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Navbar from './Navbar';
import * as userActions from '../actions/userActions';
import { getCurrentUser } from '../selectors/authSelectors';

class Settings extends Component {

  updateSettings = (e) => {
    e.preventDefault();
    const username = this.props.currentUser.username;
    const firstname = this.firstname.value;
    const lastname = this.lastname.value;

    this.props.userActions.updateSettings(username, firstname, lastname);
  }

  render() {

    const firstname = this.props.currentUser ? this.props.currentUser.first_name : '';
    const lastname = this.props.currentUser ? this.props.currentUser.last_name : '';

    return (
      <div>
        <Navbar history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 offset-md-3">
              <form>
                <div className="form-group">
                  <label className="col-form-label text-primary">Prénom</label>
                  <input className="form-control bg-irinotes-form text-muted"
                    name="firstname"
                    defaultValue={ firstname }
                    placeholder="Entrez un prénom"
                    ref={(firstname) => { this.firstname = firstname; }}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label text-primary">Nom</label>
                  <input className="form-control bg-irinotes-form text-muted"
                    name="lastname"
                    defaultValue={ lastname }
                    placeholder="Entrez un nom"
                    ref={(lastname) => { this.lastname = lastname; }}
                  />
                </div>
              </form>
              <button type="submit" className="btn btn-primary btn-lg text-secondary" onClick={this.updateSettings}>Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentUser: getCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
