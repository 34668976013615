import { Record } from '.';

export default Record({
  pk: '',
  username: '',

  email: '',
  first_name: '',
  last_name: '',
  default_group: null,

});
