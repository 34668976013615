import * as R from 'ramda';
import * as types from '../constants/actionTypes';
import SessionRecord from '../store/sessionRecord';
import { ActionEnum } from '../constants';
import { getId, idEq, getNewAction, setAction } from './utils';

export const sessions = (state = [], action) => {

  switch (action.type) {

    case types.CREATE_SESSION: {
      return R.append(SessionRecord(action.session), state);
    }
    case types.UPDATE_SESSION: {
      return R.map(R.when(idEq(action.sessionId), session => {
        return R.mergeAll([session, action.values, {action: getNewAction(R.prop('action', session))}])
      }), state);
    }
    case types.DO_DELETE_SESSION: {
      return R.reject(idEq(action.sessionId) , state);
    }
    // TODO: Change the session argument. This should not be the complete session object
    case types.DELETE_SESSION: {
      return R.map(R.when(idEq(action.sessionId), setAction(ActionEnum.DELETED) ), state);
    }
    case types.LOAD_SESSIONS: {
      return action.sessions;
    }
    case types.LOAD_SESSION: {
      const sessionRec = action.session;
      const sessionId = getId(sessionRec);
      const index = R.findIndex(idEq(sessionId), state);
      if(index >= 0) {
        return  R.update(index, sessionRec, state);
      } else {
        return R.append(sessionRec, state);
      }
    }
    case types.SYNC_RESET_ALL: {
      return R.map(setAction(ActionEnum.NONE), state);
    }
    case types.RESET_ACTION_SESSION: {
      return R.map(R.when(idEq(action.sessionId), setAction(ActionEnum.NONE)), state);
    }
    case types.AUTH_LOGOUT: {
      return []; // empty session list on logout
    }
    default:
      return state;
  }
};
