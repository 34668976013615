import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SlateEditor from './SlateEditor';
import { now } from '../utils';

class NoteInput extends Component {

  constructor(props) {
    super(props);
    this.editorInst = React.createRef();
  }

  get editor() {
    return this.editorInst.current;
  }
  state = {
    buttonDisabled: false,
    startedAt: null,
    finishedAt: null,
  }

  onEditorChange = (e) => {
    let text = '';
    if(this.editor) {
      text = this.editor.asPlain();
    }
    this.setState({
      buttonDisabled: text.length === 0,
      startedAt: e.startedAt,
      finishedAt: e.finishedAt
    });
  }

  submitNote = () => {

    const plain = this.editor.asPlain();
    const raw = this.editor.asRaw();
    const html = this.editor.asHtml();
    const categories = this.editor.asCategories();
    // const marginComment = this.marginComment.value;

    this.props.addNote(this.props.session, {
      plain: plain,
      raw: raw,
      html: html,
      startedAt: this.state.startedAt,
      finishedAt: now(),
      categories: categories,

      // marginComment: marginComment,
    });


    this.editor.clear();
    setTimeout(() => this.editor.focus(), 250);
  }

  componentDidMount() {
    if(this.editor) {
      const text = this.editor.asPlain();
      this.setState({ buttonDisabled: text.length === 0 });
    }
  }

  render() {
    return (
      <form>
        <div className="editor mb-3">
          <div className="editor-left sticky-bottom px-2">
            <SlateEditor editorRef={this.editorInst}
              onChange={this.onEditorChange}
              submitNote={this.submitNote}
              isButtonDisabled={this.state.buttonDisabled}
              annotationCategories={ this.props.annotationCategories } />

          </div>
          {/* <div className="editor-right w-25 pl-2 border-0 sticky-bottom">
            <input type="textarea" className="form-control h-100"
              name="margin"
              placeholder="Espace de commentaire pour votre note"
              // inputRef={ ref => { this.marginComment = ref; } }
              ref={(marginComment) => { this.marginComment = marginComment; }}
            />
          </div> */}
        </div>
      </form>
    );
  }
}

NoteInput.propTypes = {
  addNote: PropTypes.func.isRequired
};

export default NoteInput;
