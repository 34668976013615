import { select } from 'redux-saga/effects'
import { getCreatedSessions, getUpdatedSessions, getDeletedSessions } from '../selectors/coreSelectors';
import { ActionEnum } from '../constants';
import moment from 'moment';
import SessionRecord from '../store/sessionRecord';
import { doDeleteSession, loadSession, resetActionSession } from '../actions/sessionsActions';
import SyncMixin from './BaseSyncronizer';
import * as R from 'ramda';

class SessionSyncBase {

  * getLocalDiffs() {
    return {
      created: yield select(getCreatedSessions),
      updated: yield select(getUpdatedSessions),
      deleted: yield select(getDeletedSessions)
    }
  }

  // remote urls
  getRemoteLoadUrl() {
    return "/api/notes/sessions/";
  }

  getRemoteDeleteUrl(localObjInst) {
    return `/api/notes/sessions/${R.prop('_id',localObjInst)}/`;
  }

  getRemoteCreateUrl(localObjInst) {
    return "/api/notes/sessions/";
  }

  getRemoteUpdateUrl(localObjInst) {
    return `/api/notes/sessions/${R.prop('_id',localObjInst)}/`;
  }

  // build remote json message
  getRemoteData(localObjInst) {
    return {
      ext_id: R.prop('_id', localObjInst),
      date: R.prop('date', localObjInst),
      title: R.prop('title', localObjInst),
      description: R.prop('description', localObjInst),
      group: R.prop('group', localObjInst),
      protocol: R.prop('protocol', localObjInst),
    };
  }

  getLocalRecord(remoteObj) {
    return SessionRecord({
        _id: remoteObj.ext_id,
        title: remoteObj.title,
        description: remoteObj.description,
        date: moment(remoteObj.date).toDate(),
        action: ActionEnum.NONE,
        group: remoteObj.group,
        protocol: remoteObj.protocol,
    });
  }

  // actions
  doDeleteLocalObj(localObjId) {
    return doDeleteSession(localObjId);
  }

  resetLocalObj(localObjInst) {
    return resetActionSession(localObjInst);
  }

  loadObj(objRecord) {
    return loadSession(objRecord);
  }

}

export class SessionSynchronizer extends SyncMixin(SessionSyncBase) {}

export default SessionSynchronizer;
