import { Record } from '.';
import { ActionEnum } from '../constants';

export default Record({
  _id: '',

  title: '',
  description: '',

  date: '',

  group: null,
  protocol: '',

  action: ActionEnum.NONE,

});
