import * as types from '../constants/actionTypes';

export const autoSubmit = (state = false, action) => {
  switch (action.type) {
    case types.USER_TOGGLE_AUTO_SUBMIT:
      return action.value;
    default:
      return state;
  }
}

export const online = (state = false, action) => {
  switch (action.type) {
    case types.STATUS_ONLINE:
      return true;
    case types.STATUS_OFFLINE:
      return false;
    default:
      return state;
  }

}
