import * as types from '../constants/actionTypes';

export const updateLastSync = (timestamp) => {
  return {
    type: types.SYNC_SET_LAST_SYNC,
    value: timestamp
  }
}

export const endSynchronize = () => {
  return {
    type: types.SYNC_END_SYNC
  }
}

export const startSynchronize = () => {
  return {
    type: types.SYNC_START_SYNC
  }
}
