import { Record } from '.';
import { ActionEnum } from '../constants';

export default Record({
  _id: '',
  session: '',

  plain: '',
  raw: {},
  html: '',

  startedAt: '',
  finishedAt: '',

  categories: [],

  marginComment: '',
  action: ActionEnum.NONE
});
