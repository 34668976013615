import * as types from '../constants/actionTypes';

export const dataFetchSuccess = (res) => {
  return {
    type: types.DATA_FETCH_SUCCESS,
    res
  };
}

export const setOnlineStatus = (status) => {
  return {
    type: status?types.STATUS_ONLINE:types.STATUS_OFFLINE
  }
}


export const forceSync = () => {
  return {
    type: types.SYNC_DO_SYNC
  }
}
