import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from 'react-i18next';
import Navbar from './Navbar';
import NoteInput from './NoteInput';
import NotesList from './NotesList';
import SessionForm from './SessionForm';
import SessionSummary from './SessionSummary';
import ProtocolSummary from './ProtocolSummary';
import * as sessionsActions from '../actions/sessionsActions';
import * as notesActions from '../actions/notesActions';
import { getSession, getSessionNotes } from '../selectors/coreSelectors';
import { extractAnnotationCategories, defaultAnnotationsCategories } from '../constants';

class Session extends Component {

  state = {
    screenSummary: 0,
  }

  onClickReadOnly = (e) => {
    e.preventDefault();
    this.props.history.push('/read-only/' + this.props.match.params.id);
  }

  toggleScreenSummary = (e) => {
    if (this.state.screenSummary === 0) {
      e.preventDefault()
      this.setState({screenSummary: 1})
    }

    if (this.state.screenSummary === 1) {
      e.preventDefault()
      this.setState({screenSummary: 0})
    }
  }

  screenSummary = () => {

    if (this.state.screenSummary === 0) {
      return (
        <React.Fragment>
          <div onClick={this.toggleScreenSummary} className ="text-primary btn"><Trans i18nKey="session.protocol_display">Afficher le protocole d'annotation</Trans></div>
          <SessionSummary notes={this.props.notes} />
       </React.Fragment>
      );
    }

    if (this.state.screenSummary === 1) {
      return (
        <React.Fragment>
          <div onClick={this.toggleScreenSummary} className ="text-primary btn"><Trans i18nKey="session.summary_display">Afficher le résumé de la session</Trans></div>
          <ProtocolSummary annotationCategories={this.props.annotationCategories} />
        </React.Fragment>
      );
    }
  }

  render() {

    return (
      <div>
        <Navbar history={this.props.history} />
        <div className="session-container">
          <div className="session-notes">
            <div className="notes-affix">
              <div>
                <a onClick={this.onClickReadOnly} href="/read-only"><span className="material-icons text-primary">remove_red_eye</span></a>
              </div>
              <div className="notes-affix-summary">
                {this.screenSummary()}
              </div>
            </div>
            <div className="notes-list">
              <SessionForm session={this.props.currentSession} />
              <NotesList
                notes={this.props.notes}
                deleteNote={this.props.notesActions.deleteNote}
                updateNote={this.props.notesActions.updateNote}
                annotationCategories={this.props.annotationCategories}
              />
            </div>
        </div>
          <section className="editor-fixed pl-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 offset-md-2">
                  <NoteInput
                    session={this.props.currentSession}
                    addNote={this.props.notesActions.addNote}
                    annotationCategories={this.props.annotationCategories}/>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {

  const sessionId = props.match.params.id;

  const currentSession = getSession(sessionId, state);
  const currentNotes = getSessionNotes(sessionId, state);
  const annotationCategories = currentSession?extractAnnotationCategories(currentSession.protocol):defaultAnnotationsCategories;

  return {
    currentSession,
    notes: currentNotes,
    annotationCategories
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sessionsActions: bindActionCreators(sessionsActions, dispatch),
    notesActions: bindActionCreators(notesActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Session);
