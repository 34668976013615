import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from 'react-i18next';

import * as authActions from '../actions/authActions';
import { getLoginErrorMessages } from '../selectors/authSelectors';
import * as R from 'ramda';

class Login extends Component {

  state = {
    username: '',
    password: ''
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  login = () => {
    const { username, password } = this.state;

    this.props.authActions.loginSubmit(username, password);
  }

  submit = (e) => {
    e.preventDefault();
    this.login();
  }

  onClickRegister = (e) => {
    e.preventDefault();
    this.props.history.push('/register');
  }

  onClickReset = (e) => {
    e.preventDefault();
    this.props.history.push('/reset');
  }

  renderErrorMessage(errorMessages, fieldname) {
    if (errorMessages && errorMessages.has(fieldname)) {
      return errorMessages.get(fieldname).map((message, key) =>
        <p className="form-text" key={ key }>{ message }</p>
      );
    }
  }

  renderNonFieldErrors(errorMessages) {

    if (errorMessages && errorMessages.error) {
      return (
        <div className="alert alert-danger mt-4" role="alert">
          <Trans i18nKey="login.login_error">Unable to log in.</Trans>
        </div>
      )
    }

    const errors = R.reduce(
      (acc, p) => R.concat(acc, R.ifElse(Array.isArray, R.identity, v => [v,])(R.pathOr([], ['data', p], errorMessages))),
      [],
      ['non_field_errors', 'detail']
    );
    if (errors && errors.length > 0 ) {
      return (
        <div className="alert alert-danger mt-4" role="alert">
        { errors.map((message, key) =>
          <p key={ key }><Trans i18nKey="login.credentials_error">{ message }</Trans></p>
        ) }
        </div>
      )
    }

  }

  render() {

    const errorMessages = this.props.errorMessages;

    return (
      <div>
        {/* <Navbar history={this.props.history} /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 offset-md-3">
              <div className="panel-login panel panel-default">
                <div className="card-header bg-secondary border-0 mt-5 pt-5">
                  <h4 className="text-center card-title font-weight-bold text-lg">IRI Notes</h4>
                  <form className="pt-3 ml-5 pl-5" onSubmit={this.submit}>
                    <div className="form-group mb-2 ml-3 w-75" >
                      <label className="col-form-label text-primary font-weight-bold mt-2 text-capitalize"><Trans i18nKey="common.username">Nom d'utilisateur</Trans></label>
                      <input className="form-control bg-irinotes-form border-0 text-muted" type="text" onChange={this.handleInputChange} name="username" />
                      {/* { this.renderErrorMessage(errorMessages, 'username') } */}
                    </div>
                    <div className="form-group mb-2 ml-3 w-75">
                      <label className="col-form-label text-primary font-weight-bold mt-2 text-capitalize"><Trans i18nKey="common.password">Mot de passe</Trans></label>
                      <input className="form-control bg-irinotes-form border-0 text-muted" type="password" onChange={this.handleInputChange} name="password" />
                      {/* { this.renderErrorMessage(errorMessages, 'password') } */}
                    </div>
                    { this.renderNonFieldErrors(errorMessages) }
                    <div className="text-center mr-5 pr-5">
                    <button type="submit" className="btn btn-primary btn-lg text-secondary font-weight-bold mt-3 text-capitalize"><Trans i18nKey='common.connect'>Se connecter</Trans></button>
                    </div>
                  </form>
                </div>
              </div>
              <p className="text-center">
                <a className="text-muted" href="/register" onClick={ this.onClickRegister }><Trans i18nKey='login.registration_message'>Pas encore inscrit ? Créer un compte</Trans></a>
                &nbsp;/&nbsp;
                <a className="text-muted" href="/reset" onClick={ this.onClickReset }><Trans i18nKey='login.reset_message'>Mot de passe oublié</Trans></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    errorMessages: getLoginErrorMessages(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
