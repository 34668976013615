import React, { Component } from 'react';

class CategoriesTooltip extends Component {

  state = {
    activeCategory: null,
    showCommentControl: false
  }

  componentDidUpdate = () => {
    if (this.state.showCommentControl) {
      this.commentControl.focus();
    }
  }

  isCategoryActive = (category) => {
    return this.state.activeCategory && this.state.activeCategory.key === category.key
  }

  onButtonClick = (category) => {
    if (category.hasOwnProperty('hasComment') && category.hasComment === true) {
      this.setState({
        activeCategory: this.state.activeCategory ? null : category,
        showCommentControl: !this.state.showCommentControl
      })
    } else {
      if (typeof this.props.onCategoryClick === 'function') {
        this.props.onCategoryClick(category)
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.showCommentControl) {
      const comment = this.commentControl.value;
      const { activeCategory } = this.state;
      Object.assign(activeCategory, { comment: comment });
      if (typeof this.props.onCategoryClick === 'function') {
        this.props.onCategoryClick(activeCategory)
      }
    }
  }

  render() {
    return (
      <div className="categories-tooltip">
        <form onSubmit={ this.onSubmit }>
          <div className="form-group buttons">
          {this.props.categories.map((category) =>
            <button type="button"
              key={ category.key }
              className="btn btn-sm text-secondary"
              style={{ backgroundColor: category.color }}
              onClick={ this.onButtonClick.bind(this, category) }>{ category.name }</button>
          )}
          </div>
          {this.state.showCommentControl &&
          <div className="form-group">
            <input className="form-control"
            // inputRef={(ref) => { this.commentControl = ref; }}
            ref={(commentControl) => { this.commentControl = commentControl; }}/>
          </div>}
        </form>
      </div>
    );
  }
}

export default CategoriesTooltip
