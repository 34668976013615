import * as R from 'ramda';
import { ActionEnum } from '../constants';

export const getId = R.prop('_id');
export const idEq = R.propEq('_id');
export const setAction = actionEnum => R.flip(R.merge)({action: actionEnum})

export const getNewAction = action => {
  switch (action) {
    case ActionEnum.CREATED:
      return ActionEnum.CREATED;
    case ActionEnum.DELETED: // should not happen, but...
      return ActionEnum.DELETED;
    default:
      return ActionEnum.UPDATED;
  }
}

