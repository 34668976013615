import { Record } from '.';

export default Record({
  name: '',

  owner: '',
  isPersonal: false,
  description:'',
  protocol: null

});
