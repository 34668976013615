import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';

export default class LoginNav extends Component {

  state = {
    showDropdown: false
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    e.preventDefault();
    const currentNode = ReactDOM.findDOMNode(this);
    if(!currentNode.contains(e.target)) {
      this.hideDropDown();
    }
  }

  onClickSettings = (e) => {
    e.preventDefault();
    this.props.history.push('/settings');
  }

  onClickLogin = (e) => {
    e.preventDefault();
    this.props.history.push('/login');
  }

  toggleShowDropdown = () => {
    this.setState({showDropdown: !this.state.showDropdown});
  }

  hideDropDown = () => {
    this.setState({showDropdown: false});
  }

  render() {

    const {isAuthenticated, currentUser, onLogout} = this.props;

    if (isAuthenticated) {
      return (

        <li className={`nav-item dropdown ${this.state.showDropdown?'show':''}`}>
          <span className="nav-link dropdown-toggle username font-weight-bold" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded={this.state.showDropdown} onClick={this.toggleShowDropdown} onBlur={this.hideDropDown}>
            { currentUser.username }
            &nbsp;<span className="caret"></span>
          </span>
          <div className={`dropdown-menu dropdown-menu-right bg-primary border-0 ${this.state.showDropdown?'show':''}`} aria-labelledby="navbarDropdown">
            <button className="dropdown-item bg-primary text-secondary font-weight-bold text-capitalize" onClick={this.onClickSettings}><Trans i18nKey='common.parameters'>Paramètres</Trans></button>
            <button className="dropdown-item bg-primary text-secondary font-weight-bold text-capitalize" onClick={onLogout}><Trans i18nKey='common.disconnect'>Se déconnecter</Trans></button>
          </div>
        </li>
      );
    } else {
      return (
          <li className="nav-item">
          <a className="nav-link text-capitalize" onClick={this.onClickLogin} href="/login"><Trans i18nKey='common.connect'>Se connecter</Trans></a>
          </li>
      );
    }
  }
}
