import * as types from '../constants/actionTypes';

export const updateSettings = (username, firstname, lastname) => {
  return {
    type: types.USER_UPDATE_SETTINGS_ASYNC,
    username,
    firstname,
    lastname
  };
}

export const setAutoSubmit = (value) => {
  return {
    type: types.USER_TOGGLE_AUTO_SUBMIT,
    value
  };
}
