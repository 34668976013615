import { select } from 'redux-saga/effects'
import { getCreatedNotes, getUpdatedNotes, getDeletedNotes } from '../selectors/coreSelectors';
import NoteRecord from '../store/noteRecord';
import { doDeleteNote, loadNote, resetActionNote } from '../actions/notesActions';
import SyncMixin from './BaseSyncronizer';
import WebAnnotationSerializer from '../api/WebAnnotationSerializer';

import * as R from 'ramda';

class NoteSyncBase {

  * getLocalDiffs() {
    return {
      created: yield select(getCreatedNotes),
      updated: yield select(getUpdatedNotes),
      deleted: yield select(getDeletedNotes)
    }
  }

  // remote urls
  getRemoteLoadUrl() {
    return "/api/notes/notes/";
  }

  getRemoteDeleteUrl(localObjInst) {
    return `/api/notes/sessions/${R.prop('session',localObjInst)}/notes/${R.prop('_id',localObjInst)}/`;
  }

  getRemoteCreateUrl(localObjInst) {
    return `/api/notes/sessions/${R.prop('session',localObjInst)}/notes/`;
  }

  getRemoteUpdateUrl(localObjInst) {
    return `/api/notes/sessions/${R.prop('session',localObjInst)}/notes/${R.prop('_id',localObjInst)}/`;
  }

  // build remote json message
  getRemoteData(localObjInst) {

    return {
      ext_id: R.prop('_id',localObjInst),
      session: R.prop('session',localObjInst),
      raw: JSON.stringify(R.prop('raw',localObjInst)),
      plain: R.prop('plain',localObjInst),
      html: R.prop('html',localObjInst),
      tc_start: R.prop('startedAt',localObjInst),
      tc_end: R.prop('finishedAt',localObjInst),
      categorization: JSON.stringify(WebAnnotationSerializer.serialize(localObjInst)),
      margin_note: R.prop('marginComment',localObjInst),
    }

  }

  getLocalRecord(remoteObj) {
    return NoteRecord({
      _id: remoteObj.ext_id,
      session: remoteObj.session,
      raw: JSON.parse(remoteObj.raw),
      plain: remoteObj.plain,
      html: remoteObj.html,
      startedAt: remoteObj.tc_start,
      finishedAt: remoteObj.tc_end,
      categories: remoteObj.categorization,
      marginComment: remoteObj.margin_note,
    });
  }

  // actions
  doDeleteLocalObj(localObjId) {
    return doDeleteNote(localObjId);
  }

  resetLocalObj(localObjInst) {
    return resetActionNote(localObjInst);
  }

  loadObj(objRecord) {
    return loadNote(objRecord);
  }

}

export class NoteSynchronizer extends SyncMixin(NoteSyncBase) {}

export default NoteSynchronizer;
