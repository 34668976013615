import * as R from 'ramda';

import * as types from '../constants/actionTypes';
import NoteRecord from '../store/noteRecord';
import { ActionEnum } from '../constants';

import { getId, idEq, setAction, getNewAction } from './utils';

const sessionEq = R.propEq('session');

export default (state = [], action) => {
  switch (action.type) {
    case types.ADD_NOTE: {
      return R.append(NoteRecord(action.note), state);
    }
    case types.DELETE_NOTE: {
      return R.map(R.when(idEq(getId(action.note)), setAction(ActionEnum.DELETED) ), state);
    }
    case types.DO_DELETE_NOTE: {
      return R.reject(idEq(action.noteId), state)
    }
    case types.UPDATE_NOTE: {
      return R.map(R.when(idEq(getId(action.note)), note => {
        return R.mergeAll([note, action.data, {action: getNewAction(R.prop('action', note))}])
      }), state);
    }
    case types.DELETE_SESSION: {
      const sessionId = getId(action.session);
      return R.map(R.when(sessionEq(sessionId), setAction(ActionEnum.DELETED)), state);
    }
    case types.DO_DELETE_SESSION: {
      return R.reject(sessionEq(action.sessionId), state)
    }
    case types.RESET_ACTION_NOTE: {
      return R.map(R.when(idEq(getId(action.note)), setAction(ActionEnum.NONE)), state);
    }
    case types.SYNC_RESET_ALL: {
      return R.map(setAction(ActionEnum.NONE), state);
    }
    case types.LOAD_NOTE: {
      const noteRec = action.note;
      const noteId = getId(noteRec);
      const index = R.findIndex(idEq(noteId), state);
      if(index >= 0) {
        return  R.update(index, noteRec, state);
      } else {
        return R.append(noteRec, state);
      }
    }
    case types.AUTH_LOGOUT: {
      return []; // empty note list on logout
    }
    default:
      return state;
  }
};
