import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTimestamp } from '../utils';
import Navbar from './Navbar';
import { getSession, getSessionNotes } from '../selectors/coreSelectors';

const ReadOnlyNote = ({ note }) => {
  return (
    <div id={"note-" + note._id} className="note text-sm mr-5 pt-1">
      <div className="mr-5">
        <small className="start text-irinotes-time pt-2">{ formatTimestamp(note.startedAt) }</small>
        <small className="finish text-irinotes-time pb-2">{ formatTimestamp(note.finishedAt) }</small>
      </div>
      <div className="note-content w-100 pl-2 pt-2" dangerouslySetInnerHTML={{ __html: note.html }} />
    </div>
  );

}

class ReadOnlySession extends Component {

  onClickSession = (e) => {
    e.preventDefault();
    this.props.history.push('/sessions/' +  this.props.match.params.id)
  }

  render() {

    return (
          <div>
            <Navbar history={this.props.history} />
            <div className="ml-4">
              <div className="session-page-panel panel-default sticky-top">
                <div className="session-page-card card-body bg-secondary pr-5">
                  <div className='session-page-title border-0 bg-irinotes-headers text-muted ml-3'>
                    { this.props.currentSession.title || '&nbsp;' }
                  </div>
                  <div className="session-page-description border-0 bg-irinotes-headers text-muted ml-3" >
                    { this.props.currentSession.description || '&nbsp;' }
                  </div>
                </div>
              </div>
              <div>
                {
                  this.props.notes.map((note) =>
                    <ReadOnlyNote note={ note } key={ note._id} />
                  )
                }
              </div>
            </div>
          </div>
    );
  }
};


function mapStateToProps(state, props) {

  const sessionId = props.match.params.id;

  const currentSession = getSession(sessionId, state);
  const currentNotes = getSessionNotes(sessionId, state);

  return {
    currentSession,
    notes: currentNotes,
  };
}

export default connect(mapStateToProps)(ReadOnlySession);


