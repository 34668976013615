import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatTimestamp } from '../utils';
import SlateEditor from './SlateEditor';

class Note extends Component {

  constructor(props) {
    super(props);
    this.editorInst = React.createRef();
  }

  get editor() {
    if(this.editorInst && this.editorInst.current) {
      return this.editorInst.current;
    }
    return null;
  }

  onClickDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onDelete();
  }

  submitNote = () => {

    const plain = this.editor.asPlain();
    const raw = this.editor.asRaw();
    const html = this.editor.asHtml();
    const categories = this.editor.asCategories();
    // const marginComment = this.marginComment.value;

    const data = {
      plain,
      raw,
      html,
      categories,
      // marginComment
    }

    this.props.onSave(this.props.note, data);
  }

  onClickClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onClose();
  }

  renderNoteContent() {
    if (this.props.isEditing) {
      return (
        <div className="note-content w-100 pl-2 pt-2">
          <SlateEditor editorRef={this.editorInst}
            submitNote={ this.submitNote }
            note={ this.props.note }
            annotationCategories={ this.props.annotationCategories } />
        </div>
      )
    }

    return (
      <div className="note-content w-100 pl-2 pt-2" dangerouslySetInnerHTML={{ __html: this.props.note.html }} />
    )
  }

  renderNoteRight() {
    if (this.props.isEditing) {
      return (
        <span onClick={this.onClickClose}>
          <span className="material-icons pl-2">close</span>
        </span>
      );
    }

    return (
      <span onClick={this.onClickDelete}>
        <span className="material-icons delete-icon pl-2">delete</span>
      </span>
    )
  }

  render() {
    return (
    <div id={"note-" + this.props.note._id} className="note text-sm mr-5 pt-1"
    onClick={ this.props.onClick }>
      <div className="mr-5">
        <small className="start text-irinotes-time pt-2">{ formatTimestamp(this.props.note.startedAt) }</small>
        <small className="finish text-irinotes-time pb-2">{ formatTimestamp(this.props.note.finishedAt) }</small>
      </div>
        { this.renderNoteContent() }
        {/* { this.renderNoteMarginComment() } */}
        <div className="float-right">
        { this.renderNoteRight() }
        </div>
    </div>
    );
  };
}

Note.propTypes = {
  note: PropTypes.object.isRequired,
};

export default Note;
