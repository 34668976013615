import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from 'react-i18next';
import Modal from 'react-modal';
import moment from 'moment';
import Navbar from './Navbar';
import * as sessionsActions from '../actions/sessionsActions';
import { getActiveSessions } from '../selectors/coreSelectors';
import { getCurrentUser, getGroups, getCurrentGroup } from '../selectors/authSelectors';

class SessionList extends Component {

  state = {
      show: false,
      modalIsOpen: false,
      sessionToDelete: null,
    }

  componentWillMount() {
    Modal.setAppElement('body');
}

  doSomething(e){
    e.preventDefault();
    console.log(e.target.innerHTML);
  }

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  handleModalCloseRequest = () => {
    // opportunity to validate something and keep the modal open even if it
    // requested to be closed
    this.setState({modalIsOpen: false});
  }

  onClickDelete(session, e) {
    e.preventDefault();
    e.stopPropagation();


    this.setState({
      modalIsOpen: true,
      sessionToDelete: session
    })
  }

  showSessionsNumber = () => {
    if (this.props.sessions.size === 1)
    return (
     <span className="pb-5">{this.props.sessions.size} session</span>
    );
    return (
      <span className="pb-5">{this.props.sessions.size} sessions</span>
     )

  }

  emptyListMessage = () => {

      if (this.props.sessions.length === 0) {
      return (
       <h1 className="text-primary text-center mt-5 pt-5"><Trans i18nKey="session_list.no_session">vous n'avez créé aucune session pour le moment</Trans></h1>
      );
    }
  }

  deleteSession = () => {
    const { sessionToDelete } = this.state;

    this.props.sessionsActions.deleteSession(sessionToDelete._id);

    this.setState({
      modalIsOpen: false,
      sessionToDelete: null
    })
  }

  render() {
    return (
      <div>
        <Navbar history={this.props.history} group={this.props.currentGroup} />
        <div className="container-fluid">
          <div className="session-count fixed-top bg-secondary font-weight-bold text-irinotes-form pl-4 pb-3 mb-3 mt-5 pt-3">
          {this.showSessionsNumber()}
          {this.emptyListMessage()}
          </div>
          <div className="row mt-5 justify-content-start">
                {this.props.sessions.map((session) =>
                  <div className="session col-lg-3" key={session._id}>
                      <div className="col-md-auto w-100 m-2 p-2">
                        <div className="sessions" onClick={() => this.props.history.push('/sessions/' + session._id)}>
                          <span className="session-title text-primary">{session.title || 'Session sans titre'}<br /></span>
                          <span className="session-date text-muted">{moment(session.date).format('DD/MM/YYYY')}<br /></span>
                          <span className="session-description">{session.description}<br /></span>
                        </div>
                        <button type="button" id="delete" className="btn btn-link float-left" onClick={ this.onClickDelete.bind(this, session) }>
                          <span className="material-icons delete text-dark">delete</span>
                        </button>
                        <button type="button" className="btn btn-link float-left" onClick={() => this.props.history.push('/read-only/' + session._id)}>
                          <span className="material-icons delete text-dark">remove_red_eye</span>
                        </button>
                      </div>
                  </div>
                )}
            </div>
          </div>
        <Modal
        className="Modal__Bootstrap modal-dialog modal-dialog-centered w-25"
        // closeTimeoutMS={150}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.handleModalCloseRequest}
        >
          <div id="delete-session-modal" className="modal-content">
            <span id="delete-session-close-modal-button" className="material-icons text-right" onClick={ this.handleModalCloseRequest }>close</span>
            <div className="modal-body text-center">
            <span className="modal-text"><Trans i18nKey="session_list.delete_modal_message">Supprimer cette session ?</Trans></span>
            <button type="button" className="btn btn-danger text-secondary font-weight-bold py-1 px-2 ml-3 text-capitalize" id="delete-session-modal-button" onClick={ this.deleteSession }><Trans i18nKey="common.delete">Supprimer</Trans></button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}



function mapStateToProps(state, props) {
  return {
    sessions: getActiveSessions(state),
    currentUser: getCurrentUser(state),
    groups: getGroups(state),
    currentGroup: getCurrentGroup(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sessionsActions: bindActionCreators(sessionsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionList);
