import { ActionEnum } from '../constants';
import * as R from 'ramda';

const actionFilter = R.propEq('action');

// export const getSessions = state => state.get('sessions')
export const getSessions = R.prop('sessions')

// export const getNotes = state => state.get('notes')
export const getNotes = R.prop('notes');

// const getSessionMapSelector = actionVal => state =>
//   state.sessions
//     .filter(s => s.action === actionVal)
//     .reduce(
//       (res, obj) => {
//         res[obj._id] = obj;
//         return res;
//       },
//       {}
//     );

const groupById = R.reduceBy(R.nthArg(1), {}, R.prop('_id'));

const getSessionMapSelector = actionVal => R.compose(
  groupById,
  R.filter(actionFilter(actionVal)),
  getSessions
  );

// const getNoteMapSelector = actionVal => state => {
//   const deletedSessions = state.sessions
//     .filter(s => s.action === ActionEnum.DELETED)
//     .reduce(
//       (res, obj) => {
//         res[obj._id] = obj;
//         return res;
//       },
//       {}
//     );
//   return state.notes
//     .filter(n => (n.action === actionVal && !(n.session in deletedSessions)))
//     .reduce(
//       (res, obj) => {
//         res[obj._id] = obj;
//         return res;
//       },
//       {}
//     );
// }

const getNoteMapSelector = actionVal => state => {
  const deletedSessions = getSessionMapSelector(ActionEnum.DELETED)(state);
  return R.compose(
   groupById,
   R.reject(R.propSatisfies(R.has(R.__, deletedSessions), 'session')),
   R.filter(actionFilter(actionVal)),
   getNotes
  )(state)
}


export const getUpdatedSessions = getSessionMapSelector(ActionEnum.UPDATED);

export const getCreatedSessions = getSessionMapSelector(ActionEnum.CREATED);

export const getDeletedSessions = getSessionMapSelector(ActionEnum.DELETED);

export const getUpdatedNotes = getNoteMapSelector(ActionEnum.UPDATED);

export const getCreatedNotes = getNoteMapSelector(ActionEnum.CREATED);

export const getDeletedNotes = getNoteMapSelector(ActionEnum.DELETED);

const testActionNotDeleted = R.complement(R.propEq('action', ActionEnum.DELETED));

// export const getActiveSessions = state => getSessions(state).filter(session => session.action !== ActionEnum.DELETED)
export const getActiveSessions = R.compose(R.filter(testActionNotDeleted), getSessions);

// export const getSession = (sessionId, state) => {
//   const sessions = getSessions(state);
//   return sessions.find(session => session._id === sessionId)
// }
const findById = R.compose(R.find,R.propEq('_id'));
export const getSession = R.uncurryN(2,R.partialRight(R.useWith(R.compose, [findById, R.always(getSessions)]), [null,]))

// export const getSessionNotes = (sessionId, state) => {
//   const notes = getNotes(state);
//   let filteredNotes = notes.filter(note => {
//     return (note.session === sessionId && note.action !== ActionEnum.DELETED);
//   });
//   filteredNotes.sort((a,b) => a.startedAt - b.startedAt);
//   return filteredNotes;
// }
const filterSessionNote = R.partial(R.useWith(R.both, [R.always(testActionNotDeleted), R.propEq('session')]),[null,]);

export const getSessionNotes = R.uncurryN(2,R.converge(R.compose, [R.always(R.sortBy(R.prop('startedAt'))), R.useWith(R.filter, [filterSessionNote,]), R.always(getNotes)]));

