import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from 'react-i18next';
import Navbar from './Navbar';
import * as authActions from '../actions/authActions';
import { getOnline, getCreateGroup } from '../selectors/authSelectors';

class CreateGroup extends Component {

  state = {
    name: '',
    description: ''
  }

  createGroup = () => {

    const { name, description } = this.state;

    if(name && name.trim() !== "") {
      this.props.authActions.createGroup(name, description);
    }
  }

  submit = (e) => {
    e.preventDefault();

    this.createGroup();
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderErrorMessage(errorMessages, fieldname) {
    if (errorMessages && fieldname in errorMessages) {
      return errorMessages[fieldname].map((message, key) =>
        <p className="form-text" key={ key }>{ message }</p>
      );
    }
  }

  renderNonFieldErrors(errorMessages) {
    if (errorMessages && 'non_field_errors' in errorMessages) {
      const errors = errorMessages['non_field_errors'];
      return (
        <div class="alert alert-danger" role="alert">
        { errors.map((message, key) =>
          <p key={ key }>{ message }</p>
        ) }
        </div>
      )
    }
  }

  cancel = (e) => {
    e.preventDefault();
    this.props.history.push('/sessions');
  }


  render() {

    // const errorMessages = this.props.createGroup.getIn(['errorMessages', 'data']);
    const okDisabled = (!this.state.name || this.state.name.trim() === "");

    return (
      <div>
        <Navbar history={this.props.history} />
        <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 offset-md-5">
                <div className="panel-login panel panel-default d-flex justify-content-end">
                  <div className="card-header bg-primary w-50">
                  <h5 className="text-center text-secondary font-weight-bold text-capitalize"><Trans i18nKey="create_group.new_group">nouveau groupe</Trans></h5>
                  <form className="mt-3" onSubmit={this.submit.bind(this)}>
                    <div className="form-group mb-2" /*validationState={ errorMessages && ('name' in errorMessages) ? 'error' : null }*/>
                      <label className="col-from-label text-secondary font-weight-bold mt-2 text-capitalize"><Trans i18nKey="common.name">nom</Trans></label>
                      <input className="form-control bg-secondary text-primary border-0 w-100" type="text" onChange={this.handleInputChange} name="name" placeholder="Entrez un nom de groupe"/>
                      {/* { this.renderErrorMessage(errorMessages, 'name') } */}
                    </div>
                    <div className="form-group mb-2" /*validationState={ errorMessages && ('description' in errorMessages) ? 'error' : null }*/>
                      <label className="col-form-label text-secondary font-weight-bold mt-2 text-capitalize"><Trans i18nKey="common.description">description</Trans></label>
                      <textarea className="form-control bg-secondary text-primary border-0 w-100" type="textarea" onChange={this.handleInputChange} name="description" placeholder="Entrez une description de groupe"></textarea>
                      {/* { this.renderErrorMessage(errorMessages, 'description') } */}
                    </div>
                    {/* { this.renderNonFieldErrors(errorMessages) } */}
                    <div className="text-center">
                    <button type="submit" value="Submit" className="btn btn-secondary btn-lg text-primary font-weight-bold m-3 text-capitalize" disabled={okDisabled} onClick={this.submit}><Trans i18nKey="common.create">Créer</Trans></button>
                    <button type="button" className="btn btn-irinotes-form text-muted btn-lg font-weight-bold text-capitalize" onClick={this.cancel}><Trans i18nKey="common.cancel">annuler</Trans></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    createGroup: getCreateGroup(state),
    online: getOnline(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
