import { now } from '../utils';
import { ActionEnum } from '../constants'
import * as types from '../constants/actionTypes';


export const createSession = (sessionId, groupName, protocol, title = '', description = '') => {

  const newSession = {
      _id: sessionId,
      ext_id: sessionId,
      date: now(),
      title: title,
      description: description,
      group: groupName,
      protocol: protocol,
      action: ActionEnum.CREATED
  };

  return {
    type: types.CREATE_SESSION,
    session: newSession,
  };
}

export const updateSession = (sessionId, values) => {
  return {
    type: types.UPDATE_SESSION,
    sessionId: sessionId,
    values: values,
  };
}

export const deleteSession = (sessionId) => {
  return {
    type: types.DELETE_SESSION,
    sessionId: sessionId,
  };
}

export const doDeleteSession = (sessionId) => {
  return { type: types.DO_DELETE_SESSION, sessionId };
}

export const doDeleteAllSession = () => (
  { type: types.DO_DELETE_ALL_SESSION }
)

export const loadSessions = () => {
  return {
    type: types.LOAD_SESSIONS
  }
}

export const loadSession = (session) => {
  return { type: types.LOAD_SESSION, session };
}

export const resetActionSession = (session) => {
  return { type: types.RESET_ACTION_SESSION, sessionId: session._id };
}
