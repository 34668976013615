import { put, take, all } from 'redux-saga/effects'
import * as types from '../constants/actionTypes';
import { groupCreateSuccess, groupCreateError, groupLoadSucess, groupLoadError } from '../actions/groupActions';

function* watchCreateGroup(context) {
  while (true) {
    const { group } = yield take(types.GROUP_CREATE_ASYNC);
    const client = context.client;
    try {
      const response = yield client.post('/api/auth/group/', group);
      yield put(groupCreateSuccess(response));
      context.history.push('/sessions');
    } catch (e) {
      yield put(groupCreateError(e));
    }
  }
}

function* watchLoadGroups(context) {
  const client = context.client;
  while (true) {
    yield take(types.GROUP_LOAD_ASYNC);
    try {
      const response = yield client.get('/api/auth/group/');
      yield put(groupLoadSucess(response.results));
    } catch (e) {
      yield put(groupLoadError(e));
    }
  }
}

export default function* rootSaga(context) {
  yield all([
    watchCreateGroup(context),
    watchLoadGroups(context),
  ])
}
