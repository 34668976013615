import qs from 'qs';
import { getToken, getClientId } from '../selectors/authSelectors'

class APIClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  setStore = (store) => {
    this.store = store;
  }

  createRequest = (method, uri, data, headers) => {

    headers = headers || new Headers();
    if(method !== 'HEAD') {
      headers.append("Content-Type", "application/json");
    } else {
      headers.append("Content-Type", "text/plain");
    }


    var options = {
      method: method,
      headers: headers,
    };

    let queryparams = '';
    if (data) {
      if(method !== 'HEAD' && method !== 'GET' && data) {
        options.body = JSON.stringify(data);
      }
      else {
        queryparams = "?"+qs.stringify(data);
      }
    }

    // TODO : use URL-module to build URL
    return new Request(this.baseURL + uri + queryparams, options);
  }

  getToken = () => {
    const state = this.store.getState();
    return getToken(state);
  }

  getClientId = () => {
    const state = this.store.getState();
    return getClientId(state);
  }

  hasToken = () => {
    const token = this.getToken();

    return token !== null && token !== '';
  }

  hasClientId = () => {
    const clientId = this.getClientId();
    return clientId !== null && clientId !== '';
  }

  createAuthorizedHeader = (headers) => {
    const token = this.getToken() || '';
    headers.append("Authorization", "JWT " + token);
    return headers;
  }

  createClientIdHeader = (headers) => {
    const clientId = this.getClientId() || '';
    headers.append("Auditlog-Client", clientId);
    return headers;
  }

  request = (method, uri, data) => {
    var headers = new Headers();
    if(this.hasToken()) {
      headers = this.createAuthorizedHeader(headers);
    }
    if(this.hasClientId()) {
      headers = this.createClientIdHeader(headers);
    }
    var req = this.createRequest(method, uri, data, headers);
    return this.fetch(req, { credentials: 'include' });
  }

  get = (uri, data) => {
    return this.request('GET', uri, data);
  }

  post = (uri, data) => {
    return this.request('POST', uri, data);
  }

  put = (uri, data) => {
    return this.request('PUT', uri, data);
  }

  delete = (uri, data) => {
    return this.request('DELETE', uri, data);
  }

  fetch = (req) => {
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response.ok) {
            let resJsonPromise;
            if(response.status === 204) {
              resJsonPromise = Promise.resolve({});
            } else {
              resJsonPromise = response.text().then(data => {
                if(data.length > 0) {
                  return JSON.parse(data);
                } else {
                  return {};
                }
              });
            }
            return resJsonPromise.then(data => resolve(data));

          } else {
            let errorResp = {
              status: response.status,
              statusText: response.statusText
            }
            return response.json().then(data => reject({...errorResp,data }));
          }
        })
        .catch((error) => {
          reject({error});
        });
    });
  }
}

export default APIClient
