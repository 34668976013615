import React from 'react';
import { withNamespaces } from 'react-i18next';

/**
 * Render a mark-toggling toolbar button.
 *
 * @param {String} type
 * @param {String} icon
 * @return {Element}
 */
export default withNamespaces("")(({icon, isActive, onMouseDown, t}) => (
  <span className={"button sticky-top" + ((!isActive)?" text-primary":" text-dark")} onMouseDown={onMouseDown} data-active={isActive} title={t("common." + icon)} >

    <span className="material-icons">{icon}</span>
  </span>
));

