import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import uuidV1 from 'uuid/v1';
import { withNamespaces } from 'react-i18next';
import ProtocolSummary from './ProtocolSummary';
import { extractAnnotationCategories } from '../constants';

class CreateSession extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    group: PropTypes.object,
    createSession: PropTypes.func.isRequired,
  };

  state = {
    createGroup: false,
    protocolOpen: false,
    title: "",
    description: ""
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }


  openSessionModal = () => {
    this.setState({modalIsOpen: true});
  }

  createSession = () => {
    const sessionId = uuidV1();
    const groupName = this.props.group ? this.props.group.name : null;
    const protocol = this.props.group ? this.props.group.protocol : null;
    const {title, description} = this.state;

    this.props.createSession(sessionId, groupName, protocol, title, description);
    this.props.history.push('/sessions/' + sessionId);
  }

  getGroupProtocol = () => {
    if (this.props.group) {
      return this.props.group.protocol;
    }
    return null;
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  toggleProtocol = () => {
    this.setState({
      protocolOpen: !this.state.protocolOpen,
      show: false
    });
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  handleModalCloseRequest = (e) => {
    e.preventDefault();
    this.setState({modalIsOpen: false});
  }

  render() {

    const t = this.props.t;
    const protocol = this.getGroupProtocol();
    const categories = extractAnnotationCategories(protocol);
    const modalStyles = {overlay: {zIndex: 1021}};
    return (
      <div className="container-fluid">
      <span className="nav-link btn" onClick={this.openSessionModal}>{t('create_session.new_session')}</span>
      <Modal
      className="Modal__Bootstrap modal-dialog ml-5 mt-5 fixed-top w-100"
      isOpen={this.state.modalIsOpen}
      onRequestClose={this.handleModalCloseRequest}
      style={modalStyles}
      >
        <div className="modal-content bg-primary w-75">
          <div className="modal-body mt-3">
            <form onSubmit={ e => { e.preventDefault() } }>
              <div className="form-group">
                <label className="col-form-label text-secondary font-weight-bold pt-2 text-capitalize">{t('common.title')}</label>
                <input className="form-control text-primary w-100"
                  name="title"
                  onChange={ this.onChange }
                  type="text"
                  placeholder="Entrez un titre"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label text-secondary font-weight-bold pt-2 mt-2 text-capitalize">{t('common.description')}</label>
                <textarea className="form-control text-primary w-100"
                  type="textarea"
                  name="description"
                  onChange={ this.onChange }
                  placeholder="Entrez une description"
                  ></textarea>
              </div>
              <div className="form-group">
                <label className="col-form-label text-secondary font-weight-bold pt-2 mt-2" onClick={this.toggleProtocol}>{t('create_session.protocol')} {this.state.protocolOpen?<span className="material-icons protocol-toggle">&#xE313;</span>:<span className="material-icons protocol-toggle">&#xE315;</span>}</label>
                <div className={ "protocol collapse" + (this.state.protocolOpen?'in':'') } >
                  <ProtocolSummary annotationCategories={categories} />
                </div>
              </div>
              <div className="text-center">
              <button id="create-button" type="submit" className="btn btn-secondary btn-lg text-primary font-weight-bold m-3 text-capitalize" onClick={this.createSession}>{t('common.begin')}</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      </div>
    );
  }
}

export default withNamespaces()(CreateSession);
