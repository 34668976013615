import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import notes from './notesReducer';
import { sessions } from './sessionsReducer';
import { isAuthenticated, currentUser, currentGroup, login, register, token, groups, createGroup, clientId } from './authReducer';
import { autoSubmit, online } from './miscReducer';
import { isSynchronizing, lastSync } from './syncReducer';


export default (history, wrapper) => wrapper(combineReducers({
  router: connectRouter(history),
  sessions,
  notes,
  login,
  register,
  authStatus: combineReducers({
    token,
    currentUser,
    currentGroup,
    isAuthenticated,
    clientId,
    lastSync
  }),
  status: combineReducers({
    isSynchronizing,
    online
  }),
  autoSubmit,
  groups,
  createGroup,
}));
