import React from 'react';
import _ from 'lodash';
import Color from 'color';
import {formatTimestamp} from '../utils';

const SessionSummary = ({notes}) => (
  <div className="session-summary-list">
    <ul className="list-group sticky-left">
      {notes.map((note) => {
        let bgColor = "transparent";
        if(note.categories.length > 0) {
          const hsl = Color(note.categories[0].color).hsl();
          const l = hsl.lightness();
          bgColor = hsl.lighten(50.0/l-0.5).hex();
        }
        return (
        <li className="list-group-item border-0 py-1" key={note._id}>
            <a href={'#note-' + note._id}>
              <small className="note-time text-irinotes-time px-1 bg-irinotes-headers border-0 text-center">{formatTimestamp(note.startedAt)}</small>
              <small className="note-length font-weight-bold text-muted text-center badge" style={{ backgroundColor: bgColor }}>{_.words(note.plain).length} mots</small>
              <small className="note-time text-irinotes-time px-1 bg-irinotes-headers border-0 text-center">{formatTimestamp(note.finishedAt)}</small>
            </a>
        </li>
        )
      })}
    </ul>
  </div>
)

export default SessionSummary;
